import "@babel/polyfill";
import './public-path';

try {
    if (typeof __REACT_DEVTOOLS_GLOBAL_HOOK__ === "undefined" &&
        typeof global.parent !== "undefined" &&
        typeof global.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "undefined") {

        global.__REACT_DEVTOOLS_GLOBAL_HOOK__ = global.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;
    }
} catch (e) {
    // react dev tools might not work.
}


import { IdentityVerification } from './sdk/IdentityVerification';
import { initLoggerFactory, createLogger, eLogLevel } from "@888webteam/logger-core";
import { ConsoleLoggerFactory } from "@888webteam/logger-console";
import { eDisplayMode, eUploadDocMethod, eMode } from "./app/enums";
import v4 from "uuid/v4";

initLoggerFactory(new ConsoleLoggerFactory(eLogLevel.Debug));
let logger = createLogger("IdentityVerification");

global.IdentityVerificationDisplayMode = eDisplayMode;
global.IdentityVerificationUploadDocMethod = eUploadDocMethod;
global.IdentityVerificationMode = eMode;

const _scriptId = v4();

global.IdentityVerification = function (settings) {

    logger.log(`using IdentityVerification ${VERSION}`);

    if (settings != null &&
        settings.context != null) {

        if (typeof settings.context.sessionId === "undefined") {
            settings.context.sessionId = v4();
        }

        if (typeof settings.context.scriptId === "undefined") {
            settings.context.scriptId = _scriptId;
        }
    }

    return new IdentityVerification(settings);
};

global.initZoid = function (then) {

    import(/* webpackChunkName: "zoid" */ './zoid/IdentityVerificationZoidComponent').then(function (x) {
        x.zoidCreate();
        then();
    });
}