
let eScreen = {
    Loading: 0,
    DocumentSelection: 1,
    DrivingLicenseOnly: 2,
    Dashboard: 3,
    Error: 4,
    UploadDocument: 5,
    Support: 6,
    Confirmation: 7,
    ReviewDetails: 8,
    DocumentSelectionOnDark: 9,
    VerifiedConfirmation: 10,
    NotVerifiedConfirmation: 11,
    DarkDashboard: 12,
    CardsSelectionOnDark: 13,
    VerificationDocumentSelectionOnDark: 14,
    UploadDocumentConfirmation: 15,
    MobileVerification: 16,
    EmailVerification: 17,
    STCSuccessScreen: 18,
};

let eDisplayMode = {
    Membership: 0,
    Pending: 1,
    NTS: 2,
    MyAccount: 4,
    UploadDocument: 5
};

let eCardType = {
    DateOfBirth: 1,
    Address: 2,
    IBAN: 3,
    Mobile: 4,
    Email: 5
};

let eDocumentType = {
    IdentityCard: 1,
    DrivingLicense: 2,
    Passport: 3,
    CreditCardCopy: 4,
    Other: 5,
    CCStatement: 6, // Bank Statement 
    UtilityBill: 7,
    Declaration: 8,
    RegulationContract: 9,
    MembershipCardModAT: 10,
    MembershipCardModBT: 11,
    WeaponCarryingLicense: 12,
    SocialSecurityNumber: 13,
    BirthCertificate: 14,
    Cheque: 15,
    MarriageCertificate: 16,
    AdoptionCertificate: 17,
    ReactivationDisclaimer: 18,
    SourceOfFunds: 19,
    RMBackgroundCheck: 20,
    IDandCCCopies: 21,
    Receipt: 22,
    RentalAgreement: 23,
    HealthInsuranceIDCard: 24,
    ReturnedVoidedCheckOrOfficialLetter: 25,
    IDAndUtilityBill: 26,
    UtilityBillAndCC: 27,
    WorldCheck: 28,
    DPAandLegal: 29,
    Screenshots: 30,
    EvolutionCheck: 31,
    TemporaryStudentWorkVisa: 32,
    Occupation: 33,
    BankAccountStatement: 34,
    DirectSOFDocs: 35,
    SOFOpenBanking: 36,
    RGDirectSOFDocs: 37,
    RGSOFOpenBanking: 38,
    RGBackgroundChecks: 39,
    VIPDirectSOFDocs: 40,
    VIPSOFOpenBanking: 41,
    ThirdPartyID: 42,
    USMilitaryID: 43,
    AlienRegistrationCard: 44,
    SSNCopy: 62,
    W9Form :63
};

let eVerificationIndicationType = {
    FirstAndLastName: 0,
    DOB: 1,
    Address: 2,
    IBAN: 3,
};

let eUploadDocMethod = {
    Default: 1,
    Popup: 2,
    Callback: 3
};

let eDocumentStatus = {
    None: 1,
    Processing: 2,
    Error: 3,
    Done: 4
};

let eJumioLoadStatus = {
    InProcess: 4,
    RedirectedSuccessfully: 5,
    RedirectionError: 6
};

let eFileStatus =
{
    Pending: 1,
    Uploaded: 2,
    Failed: 3
};

let eUploadFileError = {
    TechProblem1: 9118,
    TechProblem2: 9123,
    FileType: 9122,
    FileEmpty: 9120,
    MaxUploads: 9121,

    GeneralError: 1,
    FileSize: 2,
    Duplicate: 3,
    MaxSelected: 4,
    MaxSelectedPA: 5
}

let eOfferingName = {
    2: "poker",
    3: "casino",
    4: "sport",
    5: "",
    7: ""
}

let eMode = {
    Demo: 1,
    Real: 2
}

let eProductPackages = {
    NTS: 11
}


let eLayoutSize = {
    Small: 0,
    Large: 1,
    EstablishedAtRuntime: 2
};

let eRegulations = {
    Navada: 3,
    Gibraltar: 4,
    NJ: 7,
    Germany: 12,
    Sweden: 15,
    Malta: 16,
    Pennsylvania: 17,
    Michigan: 19,
    Colorado: 20,
    Virginia: 23,
    Indiana: 24
};

const eBrands = {
    Casino888: 0,
    Poker888: 1,
    Sport888: 8,
    WSOPComNV: 60,
    WSOPComNJ: 71,
    HarrahsCasino: 72,
    NJCom: 73,
    Dk: 75,
    WSOPComPA: 82,
    De: 84,
    WSOPComMI: 85,
    SportsIllustratedCO: 86,
    Ontario: 87,
    NL: 88,
    SportsIllustratedVA: 89,
    SportsIllustratedIN: 90,
    SportsIllustratedMI: 91,
    MRGSe: 92,
    MRGDe: 93,
    MrgDk: 94,
    MRGMGA: 95,
    UK888: 96
};

const eWsopSubrands = {
    WsopNJ: 103,
    WsopNV: 86,
    WsopMI: 139,
    WsopPA: 132,    
};

const eSubBrands = {
    SlotsGermany: 137,
    PokerGermany: 138,
    SportGermany: 136,
    CasinoOntario: 141,
    PokerOntario: 142,
    SportOntario: 143,
    PokerNL: 145,
    SlotsMrGreenGermany : 154 
};

let eDisplaySkin = {
    Dark: 1
};

const eUpdateVerificationDataResponse = {
    Verified: 0,
    UnVerified: 2,
    Error: 1,
    Closed: 3
};

const eCountriesIso3 = {
    Ireland: "IRL",
    Kuwait: "KWT",
    Qatar: "QAT",
    Canada: "CAN"
};

const eFeatureFlags = {
    EnableMaltaGib: "EnableMaltaGib"
}

const eLangs = {
    Arabic: "ARA"
}

const PokerSubBrandIds = [
    106,
    81,
    114,
    45,
    126,
    132,
    138,
    139,
    1,
    80,
    142,
    103,
    97,
    99,
    101,
    118,
    130,
    145,
    163
];

const CasinoSubBrandIds = [
    107,
    82,
    113,
    46,
    125,
    133,
    137,
    0,
    33,
    111,
    53,
    72,
    78,
    79,
    141,
    104,
    105,
    98,
    100,
    102,
    119,
    129,
    144,
    162,
    165
];

const SportSubBrandIds = [
    128,
    110,
    115,
    116,
    136,
    140,
    112,
    54,
    8,
    143,
    117,
    131,
    146,
    164
];

const MrgSubBrandIds = [
    93,
    154,
];

const ClientSubBrandIds = {
    CasinoSubBrandIds,
    PokerSubBrandIds,
    SportSubBrandIds,
    MrgSubBrandIds,
};

const ReverificationReason = {
    AgeAndAddressVerified: 3,
    ElectronicVerification: 6
};

const eReverificationStatus = {
    Pass: 2,
    Required: 1
};

export {
    eScreen,
    eDocumentType,
    eVerificationIndicationType,
    eDisplayMode,
    eUploadDocMethod,
    eDocumentStatus,
    eJumioLoadStatus,
    eFileStatus,
    eUploadFileError,
    eOfferingName,
    eMode,
    eProductPackages,
    eLayoutSize,
    eRegulations,
    eBrands,
    eSubBrands,
    eUpdateVerificationDataResponse,
    eDisplaySkin,
    eCardType,
    eCountriesIso3,
    eFeatureFlags,
    eLangs,
    ClientSubBrandIds,
    ReverificationReason,
    eReverificationStatus,
    eWsopSubrands
};


