import axios from "axios";
import { createLogger } from "@888webteam/logger-core";

let _logger = createLogger("Config");
let _isConfigLoaded = false;

let _config = {
};

async function loadConfigAsync(baseUrl) {

    if (_isConfigLoaded) {
        return;
    }

    try {
        let response = await axios.get(`${baseUrl}api/config/`);

        if (response.data) {
            _config = { ..._config, ...response.data };
            _isConfigLoaded = true;
        }
    } catch (error) {
        _logger.error("loadConfigAsync", error);
    }
}

function getAppConfig() {
    return _config;
}

export { getAppConfig, loadConfigAsync };
