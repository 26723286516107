import { setTrackJsContext, setTrackJsState, setTrackJsUserData, trackJsInfo } from "./trackjs";
import { ClickstreamService, enrichUserData } from '@888webteam/clickstream';

let _state = null;
let _userData = null;
let _context = null;
let _config = null;


function setContext(context, config) {

    if (context !== null) {

        _context = Object.assign(_context || {}, context);

    }

    if (config !== null) {
        _config = Object.assign(_config || {}, config);
    }

    setTrackJsContext(_context, _config);
}

function setMonitoringState(state) {
    _state = Object.assign(_state || {}, state);
    setTrackJsState(_state);
}

function setMonitoringUserData(userData) {

    if (userData !== null &&
        typeof userData === "object") {
        _userData = Object.assign(_userData || {}, userData);

        setTrackJsUserData(userData);
    }
}

function monitor(eventName, eventProps, componentName, level ) {

    try {

        let _clickstreamService = new ClickstreamService(componentName || "Monitor");

        trackJsInfo(eventName, eventProps);

        let eventData = {};

        if (typeof eventProps === "object") {
            eventData = Object.assign(eventData, eventProps);
        }

        if (_state !== null) {
            eventData.state = _state;
        }

        enrichUserData({ initContext: _context, initConfig: _config });

        let event = {
            eventData: eventData,
            eventTypeName: eventName,
            level: level
            //correlationId: config.correlationId,
        }


        _clickstreamService.send(event);

    }
    catch (e) {
        // ignore
    }
}

export { monitor, setContext, setMonitoringState, setMonitoringUserData };