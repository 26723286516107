import { loadConfigAsync } from "../Config";
import { monitor, setContext } from "../monitoring/monitoring";
import { eMode } from "../app/enums";
import { createLogger } from "@888webteam/logger-core";

//monitor("ScriptLoaded");

export class IdentityVerification {

    constructor(settings) {

        this._logger = createLogger("IdentityVerificationSdk");

        setContext(settings.context, settings.config);
        monitor("Initializing", "IdentityVerification");

        this.settings = settings || {};
        this.context = this.settings.context || {};
        this.context.lang = typeof this.context.lang === "string" ? this.context.lang.toLowerCase() : "eng";
        this.context.mode = typeof this.context.mode === "number" ? this.context.mode : eMode.Real;
        this.config = this.settings.config || {};

        this._logger.log("Initializing", this.context);

        this.callbacks = {
            onComplete: function () {
                this.raiseOnComplete();
            }.bind(this),

            onError: function (err) {
                this.raiseOnError(err);
            }.bind(this),

            onUploadDocument: function (data) {
                this.raiseOnUploadDocument(data);
            }.bind(this),

            onReady: function () {
                this.raiseOnReady();
            }.bind(this),

            onOpenCashier: function () {
                this.raiseOnOpenCashier();
            }.bind(this),

            onUserApprovedForRegulation: function () {
                this.raiseOnUserApprovedForRegulation();
            }.bind(this)
        }
    }

    raiseOnComplete() {
        if (typeof this.settings.onComplete === "function") {
            this.settings.onComplete();
        }
    }

    raiseOnError(err) {
        if (typeof this.settings.onError === "function") {
            this.settings.onError(err);
        }
    }

    raiseOnReady() {
        if (typeof this.settings.onReady === "function") {
            this.settings.onReady();
        }
    }

    raiseOnUploadDocument(data) {
        if (typeof this.settings.onUploadDocument === "function") {
            this.settings.onUploadDocument(data);
        }
    }

    raiseOnOpenCashier() {
        if (typeof this.settings.onOpenCashier === "function") {
            this.settings.onOpenCashier();
        }
    }

    raiseOnUserApprovedForRegulation() {
        if (typeof this.settings.onUserApprovedForRegulation === "function") {
            this.settings.onUserApprovedForRegulation();
        }
    }

    render(selector, inline) {

        monitor("Rendering", "IdentityVerification");

        loadConfigAsync(__webpack_public_path__).then(() => {
            let container = document.querySelector(selector);

            if (container === null || container === undefined) {
                this.raiseOnError(`unable to find container ${selector}`);
            } else {
                if (container.hasChildNodes()) {
                    while (container.firstChild) {
                        container.removeChild(container.firstChild);
                    }
                }
                if (typeof inline === "boolean" && inline === true) {
                    this.renderReact(selector);
                } else {
                    this.renderZoid(selector);
                }
            }
        });
    }

    renderZoid(selector) {

        import(/* webpackChunkName: "zoid" */ '../zoid/IdentityVerificationZoidComponent').then(function (x) {

            let identityVerificationZoidComponent = x.zoidCreate();

            identityVerificationZoidComponent({
                context: this.context,
                config: this.config,
                onComplete: this.callbacks.onComplete,
                onError: this.callbacks.onError,
                onUploadDocument: this.callbacks.onUploadDocument,
                onReady: this.callbacks.onReady,
                onOpenCashier: this.callbacks.onOpenCashier,
                onUserApprovedForRegulation: this.callbacks.onUserApprovedForRegulation
            }).render(selector);
        }.bind(this));
    }

    renderReact(selector) {
        import(/* webpackChunkName: "react" */ '../app/index').then(function (x) {
            x.render(selector, this.context, this.config, this.callbacks);
        }.bind(this));
    }
}