
let tracker = null;

if (window.TrackJS) {
    tracker = window.TrackJS;
}

let isIVTracker = tracker && (tracker.IV || false);

if (isIVTracker) {
    tracker.configure({
        version: VERSION
    });
}

function setTrackJsContext(context, clientConfig) {

    if (tracker) {
        if (context !== null) {

            if (isIVTracker && context.sessionId !== null) {
                tracker.configure({
                    sessionId: context.sessionId
                });
            }

            tracker.addMetadata("IV-SessionID", context.sessionId);
            tracker.addMetadata("IV-brandId", context.brandId);
            tracker.addMetadata("IV-subBrandId", context.subBrandId);
            tracker.addMetadata("IV-productPackage", context.productPackage);
            tracker.addMetadata("IV-systemId", context.systemId);
            tracker.addMetadata("IV-lang", context.lang);
            tracker.addMetadata("IV-clientVersion", context.clientVersion);
            tracker.addMetadata("IV-mode", context.mode);
            tracker.addMetadata("IV-registrationCountry", context.registrationCountry);
            tracker.addMetadata("IV-offeringId", context.offeringId);
        }

        if (clientConfig != null) {
            tracker.addMetadata("IV-displayMode", clientConfig.displayMode);
            tracker.addMetadata("IV-uploadDocMethod", clientConfig.uploadDocMethod);
            tracker.addMetadata("IV-showCompleteButtons", clientConfig.showCompleteButtons);
        }
    }
}

function setTrackJsState(state) {

    if (tracker) {
        if (state !== null) {
            tracker.console.info("IV-StateChanged", state);
        }
    }
}

function setTrackJsUserData(userData) {

    if (tracker) {
        if (userData !== null) {

            if (isIVTracker && typeof userData.Cid === "number") {
                tracker.configure({
                    userId: userData.Cid.toString()
                });
            }

            tracker.addMetadata("IV-CID", userData.Cid);
            tracker.addMetadata("IV-AuthenticationId", userData.AuthenticationId);
            tracker.addMetadata("IV-InitialSessionId", userData.InitialSessionId);
            tracker.addMetadata("IV-IsPreview", userData.IsPreview);
            tracker.addMetadata("IV-RegulationType", userData.RegulationType);
        }
    }
}

function trackJsInfo(event, param) {
    if (tracker) {
        tracker.console.info("IV:", event, param);
    }
}

export { setTrackJsContext, setTrackJsState, setTrackJsUserData, trackJsInfo }